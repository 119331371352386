@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(17 24 39/1);
}

code {

}
